<template>
  <div>
    <v-card>
      <v-toolbar class="indigo darken-4 white--text" dark>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <v-toolbar-title>Grafica</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on" @click.stop="dialogBoat = true">
              <v-icon>directions_boat</v-icon>
            </v-btn>
          </template>
        </v-tooltip>

        <!-- DIALOG NEW USER -->
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <!--// Pescadores asociados vs pescadores independientes-->
            <highcharts :options="options" ref="highcharts0"></highcharts>
          </v-col>
          <v-col cols="12">
            <!--// Pescadores asociados vs pescadores independientes-->
            <highcharts :options="options1" ref="highcharts1"></highcharts>
          </v-col>
          <v-col cols="12">
            <!--// Pescadores por tipo de pesquería-->
            <highcharts :options="options2" ref="highcharts2"></highcharts>
          </v-col>
          <v-col cols="12">
            <!--// Pescadores por tipo de pesquería -->
            <highcharts :options="options3" ref="highcharts3"></highcharts>
          </v-col>
          <v-col cols="12">
            <!--// Pescadores por arte de pesca -->
            <highcharts :options="options4" ref="highcharts4"></highcharts>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import {
  getFishermanOrganizationNameUrl,
  getFishermanArtsUrl,
  getFishermanTypeFisheryUrl,
  getFishermanAsoVsIndep,
  getFishermanLugar,
  getHeader,
} from "./../../config.js";
import Highcharts3D from "highcharts/highcharts-3d";
import Highcharts from "highcharts";
Highcharts3D(Highcharts);

var options = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "Total Pescadores por lugar",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Total",
      colorByPoint: true,
      data: [],
    },
  ],
};

var options1 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "Total Pescadores por tipo asociación",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Total",
      colorByPoint: true,
      data: [],
    },
  ],
};
var options2 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "Pescadores por tipo de pesquería",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Total",
      colorByPoint: true,
      data: [],
    },
  ],
};
var options3 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "Pescadores por arte de pesca",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Total",
      colorByPoint: true,
      data: [],
    },
  ],
};
var options4 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "pescadores / asociación",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Total",
      colorByPoint: true,
      data: [],
    },
  ],
};

export default {
  data: () => ({
    options: options,
    options1: options1,
    options2: options2,
    options3: options3,
    options4: options4,
    totalPescadores: "",
  }),
  methods: {
    //Total de pescadores Providencia y Santa Catalina
    getTotalpescadoresProYSan() {
      this.axios
        .get(getFishermanLugar, { headers: getHeader() })
        .then((response) => {
          this.totalPescadores = response.data.fishes;
          //limpiar data
          options.series[0].data = [];
          this.totalPescadores.forEach((element) => {
            const dataArray = {
              name: element.location + ":" + element.location_count,
              y: element.location_count,
              sliced: true,
            };
            const arrayGraphic = options.series[0].data;
            arrayGraphic.push(dataArray);
          });
        });
    },
    // Pescadores asociados vs pescadores independientes
    getPescadoresAsovsInd() {
      this.axios
        .get(getFishermanAsoVsIndep, { headers: getHeader() })
        .then((response) => {
          this.totalPescadores = response.data.fishes;
          //limpiar data
          options1.series[0].data = [];
          this.totalPescadores.forEach((element) => {
            let asociados = "";
            if (element.organization_cooperative == 0) {
              asociados = "independientes";
            }
            if (element.organization_cooperative == 1) {
              asociados = "Asociados";
            }
            if (element.organization_cooperative == null) {
              asociados = "Desconocidos";
            }
            const dataArray = {
              name: asociados + ":" + element.organization_cooperative_count,
              y: element.organization_cooperative_count,
              sliced: true,
            };
            const arrayGraphic = options1.series[0].data;
            arrayGraphic.push(dataArray);
          });
        });
    },
    // Pescadores por tipo de pesquería
    getFishermanTypeFishery() {
      this.axios
        .get(getFishermanTypeFisheryUrl, { headers: getHeader() })
        .then((response) => {
          this.totalPescadores = response.data.fishes;
          //limpiar data
          options2.series[0].data = [];
          const arrayGraphic = options2.series[0].data;
          this.totalPescadores.forEach((element) => {
            const dataArray = {
              name:
                element.types_of_fishery +
                " : " +
                element.types_of_fishery_count,
              y: element.types_of_fishery_count,
              sliced: true,
            };
            arrayGraphic.push(dataArray);
          });
        });
    },
    // Pescadores por arte de pesca
    getFishermanArts() {
      this.axios
        .get(getFishermanArtsUrl, { headers: getHeader() })
        .then((response) => {
          this.totalPescadores = response.data.fishes;
          //limpiar data
          options3.series[0].data = [];
          this.totalPescadores.forEach((element) => {
            const dataArray = {
              name: element.fishing_arts + " : " + element.fishing_arts_count,
              y: element.fishing_arts_count,
              sliced: true,
            };
            const arrayGraphic = options3.series[0].data;
            arrayGraphic.push(dataArray);
          });
        });
    },
    // pescadores / asociación
    getFishermanOrganizationName() {
      this.axios
        .get(getFishermanOrganizationNameUrl, { headers: getHeader() })
        .then((response) => {
          this.totalPescadores = response.data.fishes;

          //limpiar data
          options4.series[0].data = [];

          this.totalPescadores.forEach((element) => {
            const dataArray = {
              name:
                element.organization_name +
                " : " +
                element.organization_name_count,
              y: element.organization_name_count,
              sliced: true,
            };
            const arrayGraphic = options4.series[0].data;
            arrayGraphic.push(dataArray);
          });
        });
    },
  },
  created() {
    this.getTotalpescadoresProYSan();
    this.getPescadoresAsovsInd();
    this.getFishermanTypeFishery();
    this.getFishermanArts();
    this.getFishermanOrganizationName();
  },
  mounted() {},
};
</script>
